/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import "./src/css/custom.scss";
import { initCookiePolicy, initOnetrustBanner } from "./src/utility/onetrust";
import { GenesysProvider } from "./src/context/genesys";

export const onRouteUpdate = () => {
  initCookiePolicy();
  if (document.getElementById("onetrust-consent-sdk")) {
    initOnetrustBanner();
  } else {
    // Options for the observer (which mutations to observe)
    const config = { attributes: false, childList: true, subtree: false };
    // Callback function to execute when mutations are observed
    const callback = (mutationList, observer) => {
      for (const mutation of mutationList) {
        if (
          mutation.type === "childList" &&
          [...mutation.addedNodes].some((node) => node.id === "onetrust-consent-sdk")
        ) {
          initOnetrustBanner();
          // Later, you can stop observing
          observer.disconnect();
        }
      }
    };
    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);
    // Start observing the target node for configured mutations
    observer.observe(document.body, config);
  }
};

export const wrapRootElement = ({ element }) => (
  <GenesysProvider>
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.GATSBY_RECAPTCHA_PUBLIC_KEY}
      language="it"
      // useRecaptchaNet="[optional_boolean_value]"
      // useEnterprise="[optional_boolean_value]"
      scriptProps={{
        async: true,
        defer: true,
      }}
    >
      {element}
    </GoogleReCaptchaProvider>
  </GenesysProvider>
);

export const shouldUpdateScroll = ({ routerProps: { location }, prevRouterProps }) => {
  const { pathname, hash } = location;
  const prevLocation = prevRouterProps?.location;
  if (!prevLocation && !hash) {
    window.scrollTo(0, 0);
    return false;
  }
  return pathname !== prevLocation?.pathname || hash;
};

export const onClientEntry = async () => {
  // IntersectionObserver polyfill for gatsby-plugin-image

  if (typeof window.IntersectionObserver === `undefined`) {
    await import(`intersection-observer`);
    console.log(`👍 IntersectionObserver is polyfilled`);
  }
};
