import React, { useContext, useState, createContext, useRef } from "react";

const GenesysContext = createContext({
  started: { current: false },
  showChat: true,
  setShowChat: () => 0,
});

export const useGenesys = () => useContext(GenesysContext);
export const GenesysProvider = ({ children }) => {
  const started = useRef(false);
  const [showChat, setShowChat] = useState(true);
  return (
    <GenesysContext.Provider value={{ started, showChat, setShowChat }}>
      {children}
    </GenesysContext.Provider>
  );
};
